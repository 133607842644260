import { useState, useEffect } from "react";
import axios from "axios";

const useGeo = () => {
  const [infos, setInfos] = useState();

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;

        setInfos(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return infos;
};

export default useGeo;
