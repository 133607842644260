import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/utils/useWindowSize";
import { isMobile } from "react-device-detect";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Space } from "antd";

import { ReactComponent as Car } from "assets/svg/Car.svg";
import { ReactComponent as Shop } from "assets/svg/Shop.svg";
import { ReactComponent as Share2 } from "assets/svg/Share2.svg";
import { ReactComponent as CarRepair } from "assets/svg/CarRepair.svg";
import { ReactComponent as CarElectric } from "assets/svg/CarElectric.svg";
import { ReactComponent as Phone } from "assets/svg/Phone.svg";
import { toggleMenuColor } from "core/store/actions";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size?.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.primary.background};
  color: ${(props) => props.theme.colors.primary.foreground};
`;

const Title = styled.h1`
  font-size: ${isMobile ? 36 : 76}px;
  font-weight: 900;
`;

const SubTitle = styled.p`
  font-size: ${isMobile ? 30 : 46}px;
  font-weight: 100;
  span {
    font-weight: 600;
  }
  margin: 0 0 ${isMobile ? 50 : 100}px 0;
`;

const IconWrapper = styled.div`
  padding: 5px ${isMobile ? 15 : 30}px;
  border-right: ${(props) => (props.last ? 0 : 1)}px solid
    ${(props) => props.theme.colors.primary.foreground};
  svg {
    width: ${isMobile ? 20 : 40}px;
    height: ${isMobile ? 20 : 40}px;
    fill: ${(props) => props.theme.colors.primary.foreground};
  }
`;

const IconsWrapper = styled.div``;

function Home() {
  const intl = useIntl();
  const size = useWindowSize();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(true));
  }, []);

  return (
    <GlobalWrapper size={size}>
      <Title>C O S M (O) B I L I S</Title>
      <SubTitle>
        {intl.formatMessage(
          { id: "views.home.SubTitle" },
          {
            span: (...chunks) => <span>{chunks}</span>,
          }
        )}
      </SubTitle>
      <IconsWrapper>
        <Space>
          <IconWrapper>
            <Car />
          </IconWrapper>
          <IconWrapper>
            <Shop />
          </IconWrapper>
          <IconWrapper>
            <Share2 />
          </IconWrapper>
          <IconWrapper>
            <CarRepair />
          </IconWrapper>
          <IconWrapper>
            <CarElectric style={{ marginTop: "5px" }} />
          </IconWrapper>
          <IconWrapper last>
            <Phone />
          </IconWrapper>
        </Space>
      </IconsWrapper>
    </GlobalWrapper>
  );
}

export default Home;
