import Home from "views/home";
import Vision from "views/vision";
import Bionic from "views/bionic";
import Platform from "views/platform";
import Structure from "views/structure";
import Team from "views/team";
import Business from "views/business";
import Business2 from "views/business2";
import Roadmap from "views/roadmap";

import Medias from "views/medias";
export const ROUTES = {
  home: {
    index: true,
    element: <Home />,
    path: "/",
    label: null,
    key: "home",
  },
  vision: {
    element: <Vision />,
    path: "/vision",
    label: "Vision",
    key: "vision",
  },
  bionic: {
    element: <Bionic />,
    path: "/bionic-company",
    label: "Bionic-company",
    key: "bionicCompany",
  },
  platform: {
    element: <Platform />,
    path: "/platform",
    label: "Platform",
    key: "platform",
  },

  business: {
    element: <Business />,
    path: "/business-model",
    label: "Business model",
    key: "businessModel",
  },
  team: {
    element: <Team />,
    path: "/team",
    label: "Team",
    key: "team",
  },
  business2: {
    element: <Business2 />,
    path: "/business-plan",
    label: "Business plan",
    key: "businessPlan",
  },
  roadmap: {
    element: <Roadmap />,
    path: "/roadmap",
    label: "roadmap",
    key: "roadmap",
  },
  medias: {
    element: <Medias />,
    path: "/medias",
    label: "Medias",
    key: "medias",
  },
};

/*

 organization: {
    element: <Structure />,
    path: "/organization",
    label: "Organization",
    key: "organization",
  },
  
 video: {
    element: <Video />,
    path: "/video",
    label: "Video",
  },
*/
