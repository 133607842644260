import React, { useState, useEffect } from "react";

import Item from "./item";

const CodeInputs = (props) => {
  const { onChange, length } = props;

  const [code, setCode] = useState(Array(length));
  const [currentInput, setCurrentInput] = useState(0);

  const onChangeItem = (i, val) => {
    const extendedCode = [...code];
    extendedCode[i] = val;
    setCode(extendedCode);
    if (val && i + 1 < length) {
      setCurrentInput(i + 1);
    }
  };

  const generateInputs = () => {
    let content = [];
    for (let i = 0; i < length; i++) {
      content.push(
        <Item
          key={i}
          id={`codeInput_${i}`}
          onChange={(val) => onChangeItem(i, val)}
        />
      );
    }
    return content;
  };

  useEffect(() => {
    const next = document.getElementById(`codeInput_${currentInput}`);

    next.focus();
  }, [currentInput]);

  useEffect(() => {
    onChange(code);
  }, [code]);
  return <>{generateInputs()}</>;
};

export default CodeInputs;
