import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "constants/routes";

import BasicLayout from "components/templates/basic-layout/";

import NoMatch from "views/noMatch";

import Login from "views/login";

import ScrollToTop from "./scrollToTop";

export function RequireAuth(props) {
  const { children } = props;

  //const { user } = useAuth();
  const isNotVerified = useSelector((state) => state.fuzio.user.bTokenExpire);

  if (isNotVerified) {
    return <Navigate to={"/login"} replace />;
  }

  return children;
}

export const PAGES = Object.values(ROUTES).map((el) => {
  return {
    ...el,
    element: <RequireAuth>{el.element}</RequireAuth>,
  };
});

PAGES.push(
  { path: "/:token", element: <Login /> },
  {
    path: ROUTES.login,
    element: <Login />,
  },
  { path: "*", element: <NoMatch /> }
);

function Routing() {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  const [transitionStage, setTransistionStage] = useState("fade");
  const [hiddenContent, setHiddenContent] = useState(true);

  useEffect(() => {
    if (location.pathname === ROUTES.NotAllowed) {
      setDisplayLocation(location);
      //return false;
    } else {
      if (location !== displayLocation) {
        setTransistionStage("fade");
      }
    }
  }, [location, displayLocation]);

  let routes = useRoutes([
    {
      path: ROUTES.home.path,
      element: <BasicLayout />,
      children: [...PAGES],
    },
  ]);

  return (
    <ScrollToTop>
      <div
        className={`${transitionStage} ${hiddenContent ? "hidden" : ""}`}
        onAnimationStart={() => {
          if (transitionStage === "fade") {
            setHiddenContent(false);
          } else {
            setHiddenContent(false);
          }
        }}
        onAnimationEnd={() => {
          if (transitionStage === "fade") {
            setTransistionStage("fade");
            setDisplayLocation(location);
          }
        }}
      >
        {routes}
      </div>
    </ScrollToTop>
  );
}
export default Routing;
